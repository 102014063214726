<template lang="pug">
.figure(:class="classes")
  .figure__content
    .figure__image(v-if="image || $slots.image" :style="styles")
      slot(name="image")
        img(:src="'/images/' + image + '.svg'")
    .figure__title(v-if="splashTitle") Маркетплейс бизнес&#8209;активов и&nbsp;залогового&nbsp;имущества
    .figure__title(v-else) {{ title }}
    .figure__description(v-if="$slots.default || description")
      slot
        | {{ description }}
</template>

<script>
  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    name: 'titled-image',
    mixins: [measurable],
    props: {
      fill: { type: Boolean, default: false },
      splashTitle: { type: Boolean, default: false },
      small: { type: Boolean, default: false },
      image: { type: String, default: undefined },
      title: { type: String, default: undefined },
      description: { type: String, default: undefined },
    },

    computed: {
      classes() {
        return {
          'figure--fill': this.fill,
          'figure--small': this.small,
        };
      },

      styles() {
        return {
          ...this.measurableStyles,
        };
      },
    },
  };
</script>

<style lang="scss">
  .figure {
    display: flex;
    justify-content: center;
    align-items: center;

    .figure__content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .figure__image {
      }

      .figure__title {
        @include text-style('h1-mobile', 'light');
        text-align: center;
        margin-top: 24px;
      }

      .figure__description {
        @include text-style('caption-normal', 'light');
        color: c('black80', 'light');
        text-align: center;
        margin-top: 12px;
      }
    }

    &.figure--fill {
      width: 100%;
      height: 100%;
    }

    &.figure--small {
      .figure__title {
        @include text-style('h3-mobile', 'light');
        margin-top: 4px;
      }

      .figure__description {
        margin-top: 8px;
      }
    }
  }
</style>
