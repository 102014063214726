<template lang="pug">
app-layout
  v-app.layout-full
    .page-splash
      .page-splash__toolbar
        ui-icon.c-black70(name="close" @click="setRoute(prevRoute)")
      .page-splash__content.page-splash-content
        portal-da-logo.page-splash-content__logo(width=180)
        .page-splash-content__figure
          titled-image.mx-3.mb-13(image="splash-image" splash-title)
        .page-splash-content__footer
          get-apps.mb-4
          ui-btn(
            large
            primary
            width="100%"
            @click="setRoute(prevRoute)"
          ) Остаться на сайте
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import GetApps from '@/components/app/GetApps.vue';
  import TitledImage from '@/components/TitledImage.vue';

  import { UiIcon, UiBtn } from '@/uikit';
  import PortalDaLogo from '@/components/PortalDaLogo';

  export default {
    name: 'page-splash',
    components: { AppLayout, PortalDaLogo, UiBtn, UiIcon, TitledImage, GetApps },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevRoute = from.fullPath;
      });
    },

    data: () => ({
      prevRoute: '/',
    }),

    methods: {
      setRoute(route) {
        this.$router.push(route);
      },
    },
  };
</script>

<style lang="scss">
  .page-splash {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    height: 100%;
    width: 100%;
    z-index: 6;

    &__toolbar {
      display: flex;
      align-self: flex-end;
    }

    &__content.page-splash-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .figure__title {
        // white-space: nowrap;
      }
    }
  }
</style>
